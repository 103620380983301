@import url("./lib/fonts/fonts.css");
@import url("./lib/icons.less");
@import url("./lib/settings.less");
@import url("./lib/functions.less");
@import url("./lib/animations.less");
@import url("./classes/classes.less");


#scroll-box{

    display:block;
    width:100%;
    height:100%;
    margin:0;
    padding:0;
    overflow:hidden;
    overflow-y:scroll;

    .no-scrollbar;
    .animation-scale;

    ul{

        display:block;
        list-style:none;
        margin:0 0 10% 0;
        padding:0;
        //padding:@p_main;

        .displayFlex(row);
        .alignContent(center);
        .justifyContent(flex-start);
        .alignItems(flex-start);
        .flexWrap(wrap);

        li{
            display:block;
            height:auto;

            transition: all 200ms;
            animation: scale-box 200ms ease-out normal backwards;

            //.transform2d(@scale: 0.155);
            .standardAnimation(all);

            &.grid-box-1{ animation-delay:0;}
            &.grid-box-2{ animation-delay:50ms;}
            &.grid-box-3{ animation-delay:100ms;}
            &.grid-box-4{ animation-delay:150ms;}
            &.grid-box-5{ animation-delay:50ms;}
            &.grid-box-6{ animation-delay:100ms;}
            &.grid-box-7{ animation-delay:150ms;}
            &.grid-box-8{ animation-delay:200ms;}
            &.grid-box-9{ animation-delay:100ms;}
            &.grid-box-10{ animation-delay:150ms;}
            &.grid-box-11{ animation-delay:200ms;}
            &.grid-box-12{ animation-delay:250ms;}
            &.grid-box-13{ animation-delay:300ms;}
            &.grid-box-14{ animation-delay:300ms;}
            &.grid-box-15{ animation-delay:300ms;}
            &.grid-box-16{ animation-delay:300ms;}
            &.grid-box-17{ animation-delay:300ms;}
            &.grid-box-18{ animation-delay:300ms;}
            &.grid-box-19{ animation-delay:300ms;}
            &.grid-box-20{ animation-delay:300ms;}
            &.grid-box-21{ animation-delay:300ms;}
            &.grid-box-22{ animation-delay:300ms;}

            .img-wrapper{

                display:block;
                width:100%;
                height:auto;
                //border:1px solid @c_5th;

                .shadow2;

                img{
                    display:block;
                    width:100%;
                    height:100%;
                }
            }
            &.grid-box-small{
                width:15.5%;
                margin-bottom:@p_main;
                margin-right:@p_main;

                @media screen and ( max-width: @q_1st ){
                    margin-right: ( @p_main / 2 ) ;
                }
            }
            &.grid-box-big{
                width:80%;
                margin:5% 10% 0 10%;
                .shadow3;
            }
            &.grid-box-off{
                .transform2d(@scale: 0);
                margin:0;
                padding:0;
            }
            &:nth-child(6n+6){
                margin-right:0;
            }
        }
    }
}

h1{
    margin-top:0;
    margin-bottom: ( @p_main * 2 )!important;
}




#oem-standards, #external-network, #press, #trusted-training, #image-film{

    display:block;
    height:100%;
    padding: 40px;

    span.close-btn{

        display:block;
        width:70px!important;
        height:70px!important;
        margin:0;
        padding:0;
        padding-bottom:5px;
        position:absolute;
        top:0;
        right: ( @p_main * 2 );
        background-color:@c_6th!important;
        z-index:10;
        border-radius: 0 0 50% 50%;

        &:before{
            content:" ";
            display:block;
            width:55px;
            height:55px;
            background-image: url("/assets/img/svg/icon_close_zeichen.svg");
            margin: 15px 7.5px 7.5px 7.5px ;
            padding:0;

        }
    }

    #oem-standards-content, #external-network-content, #press-content, #trusted-training, #image-film{

        display:block;
        width:100%;
        height:100%;

        .wrapper-3D{

            display:block;
            width:100%;
            height:100%;

            .box-3D{

                display:block;
                width:100%;
                height:100%;
                background-color:@c_6th;

            }
        }
    }
}

#trusted-training{

    display:block;
    height:100%;
    padding:20px;

    #trusted-training-content{

        display:block;
        height:100%;

        ul{

            list-style:none;
            margin:0;
            padding:0;
            width:100%;
            height:100%;

            .displayFlex(row);
            .justifyContent(space-between);
            .alignContent(flex-start);
            .alignItems(flex-start);
            .flexWrap(nowrap);

            li{

                display:block;
                margin:0;
                padding:0;
                width:24%;
                height:0;
                position:relative;

                .standardAnimation(all);

                div.wrapper-3D{

                    display:block;
                    width:100%;
                    height:100%;
                    perspective: 1800px;

                    .box-3D{

                        display:block;
                        width:100%;
                        height:100%;
                        transform-style:preserve-3d;
                        transform: rotate3d(0, 1,0,90deg);

                        .standardAnimation(all, @_time:250ms);

                        &.afterAnimation{
                            transform: rotate3d(0, 1,0,0);
                        }

                        .text-container{
                            display:block;
                            margin:0;
                            width:100%-(11);
                            height:auto;
                            padding: 0 @p_main;
                            position:absolute;
                            top:65%;

                            p,h3{
                                position:relative;
                                z-index:15;
                            }

                            h3{
                                margin:0;
                                display:block;
                                width:100%;
                                color:@c_5th;
                                text-align:center;
                                font-size:( @f_mainSize * 2 );

                                .f_1stFontBold;

                                @media screen and ( max-width: @q_1st ){
                                    font-size: ( @f_mainSize * 1.5 );
                                }
                            }
                            p{
                                text-align:center;
                                color:@c_5th;
                                font-size: ( @f_mainSize * 1.25 );
                                line-height:130%;

                                .f_1stFont;

                                @media screen and ( max-width: @q_1st ){
                                    line-height:110%;
                                    font-size: @f_mainSize;
                                }
                            }
                            @media screen and ( max-width: @q_1st ){
                                padding: 0 ( @p_main / 2 ) ;
                                top: 75%;
                            }
                        }
                        .img-box{
                            display:block;
                            width:100%;
                            height:100%;
                            background-color:@c_7th;

                            .img-box-side{
                                display:block;
                                width:2px;
                                height:100%;
                                background-color:@c_5th;
                                opacity:1;
                                transform-style:preserve-3d;
                                transform-origin:0% 50%;
                                transform: rotate3d(0,1,0, -91deg)
                            }
                        }
                    }
                }
                &.fade-1{
                    h3{
                        color:@c_5th!important;
                    }
                    .img-box{
                        background-image:url('/assets/img/about/bg_img_factory_marketing_theoretical.png');
                        .filter(@_property: grayscale, @_value: 1);

                        &:before{
                            content:" ";
                            display:block;
                            width:100%;
                            height:75%;
                            position:absolute;
                            bottom:0;

                            .gradient5();

                            @media screen and ( max-width: @q_1st ){
                                height: 75%;
                            }
                        }
                        @media screen and ( max-width: @q_1st ){
                            background-position: center center;
                            background-size: cover;
                            background-repeat:no-repeat;
                        }
                    }
                }
                &.fade-2{
                     h3{
                         color:@c_5th!important;
                     }
                     .img-box{
                         background-image:url('/assets/img/about/bg_img_about_trusted_training_labor.jpg');
                         .filter(@_property: grayscale, @_value: 1);

                         &:before{
                             content:" ";
                             display:block;
                             width:100%;
                             height:65%;
                             opacity:0.8;
                             position:absolute;
                             bottom:0;

                             .gradient5();

                             @media screen and ( max-width: @q_1st ){
                                height: 75%;
                                opacity:0.9;
                            }
                         }
                         @media screen and ( max-width: @q_1st ){
                             //background-position: center center;
                             background-size: cover;
                             background-repeat:no-repeat;
                         }
                     }
                 }
                &.fade-3{
                    h3{
                        color:@c_5th!important;
                    }
                    .img-box{
                        background-image:url('/assets/img/about/bg_img_factory_marketing_workshop_2.png');
                        .filter(@_property: grayscale, @_value: 1);

                        &:before{
                            content:" ";
                            display:block;
                            width:100%;
                            height:65%;
                            opacity:0.85;
                            position:absolute;
                            bottom:0;

                            .gradient5();

                            @media screen and ( max-width: @q_1st ){
                                height:65%;
                                opacity:0.95;
                            }
                        }
                        @media screen and ( max-width: @q_1st ){
                            background-position: center right;
                            background-size: cover;
                            background-repeat:no-repeat;
                        }
                    }

                }
                &.fade-4{
                    h3{
                        color:@c_5th!important;
                    }
                    .img-box{
                        background-image:url('/assets/img/about/bg_img_trusted_training_marketing_sales_2.jpg');
                        background-position:center;
                        .filter(@_property: grayscale, @_value: 1);

                        &:before{
                            content:" ";
                            display:block;
                            width:100%;
                            height:75%;
                            opacity:0.95;
                            position:absolute;
                            bottom:0;

                            .gradient5();

                            @media screen and ( max-width: @q_1st ){
                                height:75%;
                            }
                        }
                        @media screen and ( max-width: @q_1st ){
                            background-position: center center;
                            background-size: cover;
                            background-repeat:no-repeat;
                        }
                    }
                }
            }
        }
    }
}

#image-film{

    padding:0!important;
    position: relative;
    .animation-scale;

    #image-film-content{

        display:block;
    
        #vid-controls-image{
				
            width:100%;
            height:1080px;
            margin:0;
            padding:0;
            z-index:5;
            position:absolute;
            background-color: transparent;
            display:block;
            
            #wrapper-control-btn-ul{

                display:none;
                width:100%;
                height:100%;
                margin:0;
                padding:0;
                position:relative;
                z-index:6;
                background-color: rgba(255,255,255,0.65);

                ul#vid-control-btn{
                    
                    display:inline-block;
                    position:absolute;

                    top:50%;
                    left:50%;
                    background-color: rgba(255,255,255,0.45);
                    border: 1px solid @c_3rd;
                    border-radius: @r_borderRadius;
                    margin:0;
                    padding:0;
                    list-style:none;

                    .transform2d(@translateX: -50%, @translateY: -50%);
                    
                    .displayFlex(row);
                    .flexWrap(nowrap);
                    .justifyContent(center);
                    .alignContent(center);
                    .alignItems(center);
                    
                    li{
                        margin:0;
                        padding:@p_main;
                        width:110px;
                        height:110px;
                        
                        div{
                            padding: ( @p_main / 2 );
                            width:90px;
                            height:90px;
                            
                            i{
                                
                                display:inline-block;
                                width:100%;
                                height:100%;
                                
                                &:before{
                                    content:" ";
                                    display:block;
                                    width:100%;
                                    height:100%;
                                    margin:0;
                                    padding:0;
                                    background-color:@c_3rd;
                                }
                            }
                            &.btn-close-player-image{
                                i{
                                    &:before{
                                        mask-image: url("/assets/img/svg/times-circle-regular.svg");
                                        -webkit-mask-image: url("/assets/img/svg/times-circle-regular.svg");
                                    }
                                }
                            }
                            &.play-video{
                                i{
                                    &:before{
                                        mask-image: url("/assets/img/svg/pause-circle-regular.svg");
                                        -webkit-mask-image: url("/assets/img/svg/pause-circle-regular.svg");
                                    }
                                }
                            }
                            &.pause-video{
                                i{
                                    &:before{
                                        mask-image: url("/assets/img/svg/play-circle-regular.svg");
                                        -webkit-mask-image: url("/assets/img/svg/play-circle-regular.svg");
                                    }
                                }
                            }
                            &.btn-muted-player-image{
                                i{
                                    width:70px;
                                    height:70px;
                                    border:10px solid @c_3rd;
                                    border-radius:50%;
                                    
                                    &:before{
                                        margin:0 auto;
                                        width:35px;
                                        height:70px;
                                        background-repeat:no-repeat;
                                        overflow:hidden;
                                        mask-image: url("/assets/img/svg/volume-off-solid.svg");
                                        -webkit-mask-image: url("/assets/img/svg/volume-off-solid.svg");
                                    }
                                }
                                &.sound-on{
                                    i{
                                        &:after{
                                            content:" ";
                                            width:10px;
                                            height:110px;
                                            display:block;
                                            background-color:@c_3rd;
                                            margin:-90px 0 0 30px;
        
                                            .transform2d(@rotate: -45deg);
                                        }
                                    }
                                }
                                &.sound-off{
                                    i{
                                        &:after{
                                            content:" ";
                                            width:10px;
                                            height:110px;
                                            display:none;
                                            background-color:@c_3rd;
                                            margin:-90px 0 0 30px;
        
                                            .transform2d(@rotate: -45deg);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .block-load-box{
            display:block;
            //position:absolute;
            //margin-top: 0 ;
            //top:0;
            //left:0;
        
            video{
                display:block;
                width:100%;
                height:auto;
                position:relative;
                border: 1px solid transparent;
            }
            
            input[type=range]{

                -webkit-appearance: none;
                display:block;
                position:absolute;
                bottom:-75px;
                left:0;
                z-index:101;
                width:100%;
                height:75px;

                &::-webkit-slider-runnable-track {
                    .track();
                    //.shadow(@track-shadow-size,@track-shadow-blur,@track-shadow-color);
                    background: @c_6th;
                    border-radius: @track-radius;
                    border: @track-border-width solid @c_5th;
                }
                &::-webkit-slider-thumb {
                    .thumb();
                    -webkit-appearance: none;
                    margin-top: ((-@track-border-width * 2 + @track-height) / 2) - (@thumb-height / 2);
                }
                &:focus::-webkit-slider-runnable-track {
                    background: lighten(@c_6th, @contrast);
                }
                &::-moz-range-track {
                    .track();
                    //.shadow(@track-shadow-size,@track-shadow-blur,@track-shadow-color);
                    background: @c_6th;
                    border-radius: @track-radius;
                    border: 10px solid @c_5th;
                }
                &::-moz-range-thumb {
                    .thumb();
                }
                &::-ms-track {
                    .track();
                    background: transparent;
                    border-color: transparent;
                    border-width: @thumb-width 0;
                    color: transparent;
                }
                &::-ms-fill-lower {
                    background: darken(@track-color, @contrast);
                    border: @track-border-width solid @c_5th;
                    border-radius: @track-radius*2;
                    //.shadow(@track-shadow-size,@track-shadow-blur,@track-shadow-color);
                }
                &::-ms-fill-upper {
                    background: @track-color;
                    border: @track-border-width solid @c_5th;
                    border-radius: @track-radius*2;
                    //.shadow(@track-shadow-size,@track-shadow-blur,@track-shadow-color);
                }
                &::-ms-thumb {
                    .thumb();
                }
                &:focus::-ms-fill-lower {
                    background: @track-color;
                }
                &:focus::-ms-fill-upper {
                    background: lighten(@track-color, @contrast);
                }
            }
        }
        
    }
}